export const ADMIN_START = 'ADMIN_START';
export const ADMIN_FAIL = 'ADMIN_FAIL';

export const GET_ADMIN_LIST_SAGA = 'GET_ADMIN_LIST_SAGA';
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS';

export const UPDATE_ADMIN_SAGA = 'UPDATE_ADMIN_SAGA';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';

export const GET_ADMIN_DETAILS_SAGA = 'GET_ADMIN_DETAILS_SAGA';
export const GET_ADMIN_DETAILS_SUCCESS = 'GET_ADMIN_DETAILS_SUCCESS';

export const DELETE_ADMIN_SAGA = 'DELETE_ADMIN_SAGA';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';

export const UPDATE_ADMIN_STATUS_SAGA = 'UPDATE_ADMIN_STATUS_SAGA';
export const UPDATE_ADMIN_STATUS_SUCCESS = 'UPDATE_ADMIN_STATUS_SUCCESS';

export const TEMP_PWD_SAGA = 'TEMP_PWD_SAGA';
export const TEMP_PWD_SUCCESS = 'TEMP_PWD_SUCCESS';
