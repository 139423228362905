export const GET_EARLY_USER = 'GET_EARLY_USER';
export const GET_EARLY_USER_SUCCESS = 'GET_EARLY_USER_SUCCESS';
export const GET_EARLY_USER_START = 'GET_EARLY_USER_START';
export const GET_EARLY_USER_FAIL = 'GET_EARLY_USER_FAIL';
export const SEND_PASSWORD = 'SEND_PASSWORD';
export const SEND_PASSWORD_START = 'SEND_PASSWORD_START';
export const SEND_PASSWORD_FAIL = 'SEND_PASSWORD_FAIL';
export const SEND_PASSWORD_SUCCESS = 'SEND_PASSWORD_SUCCESS';
export const ADD_INVESTOR = 'ADD_INVESTOR';
export const ADD_INVESTOR_START = 'ADD_INVESTOR_START';
export const ADD_INVESTOR_SUCCESS = 'ADD_INVESTOR_SUCCESS';
export const ADD_INVESTOR_FAIL = 'ADD_INVESTOR_FAIL';
export const SAVED_ITEM = 'SAVED_ITEM';
// Profile

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_START = 'GET_USER_PROFILE_START';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAIL = 'GET_USER_PROFILE_FAIL';

export const GET_WALLET_ADDRESS_SAGA = "GET_WALLET_ADDRESS_SAGA";
export const GET_WALLET_ADDRESS_START = "GET_WALLET_ADDRESS_START";
export const GET_WALLET_ADDRESS_SUCCESS = "GET_WALLET_ADDRESS_SUCCESS";
export const GET_WALLET_ADDRESS_FAIL = "GET_WALLET_ADDRESS_FAIL";

export const GET_MOONPAY_URL_SAGA = "GET_MOONPAY_URL_SAGA";
export const GET_MOONPAY_URL_START = "GET_MOONPAY_URL_START";
export const GET_MOONPAY_URL_SUCCESS = "GET_MOONPAY_URL_SUCCESS";
export const GET_MOONPAY_URL_FAIL = "GET_MOONPAY_URL_FAIL";

export const GET_PAYMENT_METHODS_SAGA = "GET_PAYMENT_METHODS_SAGA";
export const GET_PAYMENT_METHODS_START = "GET_PAYMENT_METHODS_START";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";
