export const dataItemsAdmin = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    logo: 'uil-home-alt me-2',
  },
  {
    name: 'Admins',
    link: '/admin-management',
    logo: 'uil-user-circle me-2',
  },
  {
    name: 'Users',
    logo: 'uil-user me-2',
    nestedtab: [
      {
        name: 'Early Investors',
        link: '/early-investors',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Investors',
        link: '/investors',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      // {
      //   name: 'Investor Relations',
      //   link: '/investor-relations',
      //   logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      // },
      {
        name: 'Property Managers',
        link: '/property-managers',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  },
  {
    name: 'Properties',
    logo: 'uil-home me-2',
    nestedtab: [
      {
        name: 'Draft Properties',
        link: '/draft-properties',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Published Properties',
        link: '/published-properties',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  },
  {
    name: 'Transactions',
    logo: 'uil-building me-2 font-size-18 align-middle text-muted',
    link: '/transactions-management',
    // nestedtab: [
    //   {
    //     name: 'All Txn.',
    //     link: '/transactions-management',
    //     logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    //   },
    //   {
    //     name: 'Rewards Txn.',
    //     link: '/rewards-transactions',
    //     logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    //   },
    // ],
  },
  // {
  //   name: 'Governance',
  //   link: '/governance',
  //   logo: 'uil-building me-2',
  // },
  /*
  {
    name: 'My wallet',
    logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    nestedtab: [
      {
        name: 'Fiat Wallet',
        link: '/fiat-wallet',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Exchange Wallet',
        link: '/my-wallet/exchange',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  }, */
  // {
  //   name: 'My wallet',
  //   link: '/wallet',
  //   logo: 'uil-wallet me-2',
  // },
  {
    name: 'Cashflow',
    link: '/cashflow',
    logo: 'uil-money-bill me-2',
  },
  // {
  //   name: 'Account Details',
  //   link: '/account-details',
  //   logo: 'uil-money-bill me-2',
  // },
  // {
  //   name: 'Payment Methods',
  //   link: '/payment-methods',
  //   logo: 'uil-lock-access me-2',
  // },
  {
    name: 'Property Financials',
    link: '/property-financials',
    logo: 'uil-dollar-sign me-2',
  },
  {
    name: 'Market Management',
    link: '/market-management',
    logo: 'uil-chat me-2',
  },
  // {
  //   name: 'Promotions',
  //   link: '/promotions',
  //   logo: 'uil-gift me-2',
  // },
  {
    name: 'Comparable Properties',
    link: '/comparable-property',
    logo: 'uil-home me-2',
  },
  /* {
    name: 'Blog',
    logo: 'uil-blogger-alt me-2',
    nestedtab: [
      {
        name: 'Blogs',
        link: '/blogs',
        logo: 'uil uil-blogger-alt font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Type',
        link: '/blogs/type',
        logo: 'uil uil-brightness-empty font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Writer',
        link: '/blogs/writer',
        logo: 'uil uil-book-open font-size-18 align-middle me-2 text-muted',
      },
    ]
  } */
];

export const dataItems = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    logo: 'uil-home-alt me-2',
  },
  {
    name: 'Users',
    logo: 'uil-user me-2',
    nestedtab: [
      {
        name: 'Early Investors',
        link: '/early-investors',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Investors',
        link: '/investors',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      // {
      //   name: 'Investor Relations',
      //   link: '/investor-relations',
      //   logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      // },
      {
        name: 'Property Managers',
        link: '/property-managers',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  },
  {
    name: 'Properties',
    logo: 'uil-home me-2',
    nestedtab: [
      {
        name: 'Draft Properties',
        link: '/draft-properties',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Published Properties',
        link: '/published-properties',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  },
  {
    name: 'Transactions',
    logo: 'uil-building me-2 text-muted font-size-18 align-middle',
    link: '/transactions-management',

    // nestedtab: [
    //   {
    //     name: 'All Txn.',
    //     link: '/transactions-management',
    //     logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    //   },
    //   {
    //     name: 'Rewards Txn.',
    //     link: '/rewards-transactions',
    //     logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    //   },
    // ],
  },
  /* {
    name: 'Governance',
    link: '/governance',
    logo: 'uil-building me-2',
  }, */
  /*
  {
    name: 'My wallet',
    logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
    nestedtab: [
      {
        name: 'Fiat Wallet',
        link: '/fiat-wallet',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
      {
        name: 'Exchange Wallet',
        link: '/my-wallet/exchange',
        logo: 'uil uil-wallet font-size-18 align-middle me-2 text-muted',
      },
    ],
  }, */
  {
    name: 'Cashflow',
    link: '/cashflow',
    logo: 'uil-money-bill me-2',
  },
  {
    name: 'Market Management',
    link: '/market-management',
    logo: 'uil-money-bill me-2',
  },
  {
    name: 'Promotions',
    link: '/promotions',
    logo: 'uil-gift me-2',
  },
  {
    name: 'Comparable Properties',
    link: '/comparable-property',
    logo: 'uil-home me-2',
  },
];
