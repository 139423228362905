import errorHandler from 'utils/apiHandler';
import { put } from 'redux-saga/effects';

import {
  getDashboardStatsFail,
  getDashboardStatsSuccess,
  getDashboardStatsStart,
} from 'store/actions';

// get dashboard stats saga
export function* getDashboardStatsSaga() {
  yield put(getDashboardStatsStart());
  yield errorHandler({
    endpoint: '/dashboard/stats',
    successHandler: yield function* (response) {
      const { data } = response;
      yield put(getDashboardStatsSuccess(data));
    },
    failHandler: yield function* (response) {
      yield put(getDashboardStatsFail(response));
    },
    baseAxios: process.env.REACT_APP_END_POINT_URL_ADMIN,
    failHandlerType: 'CUSTOM',
    apiType: 'get',
    token: true,
  });
}
