export * from './auth/auth';
export * from './modal/modal';
export * from './user/user';
export * from './account/account';
export * from './admins';
export * from './market/market';
export * from './property/property'
export * from './portfolio'
export * from './common'
export * from './dashboard/dashboard'

export const resetApp = payload => ({
  type: 'RESET_APP',
  payload,
});
