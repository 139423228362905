export const RESET_APP = 'RESET_APP';

export const LOGIN_SAGA = 'LOGIN_SAGA';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const OTP_VERIFY = 'OTP_VERIFY';
export const OTP_VERIFY_SUCCESS = 'OTP_VERIFY_SUCCESS';
export const OTP_VERIFY_FAIL = 'OTP_VERIFY_FAIL';
export const OTP_VERIFY_START = 'OTP_VERIFY_START';
export const AUTHENTICATION_VALIDATOR = 'AUTHENTICATION_VALIDATOR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_START = 'RESET_PASSWORDSTART'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
